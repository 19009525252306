import { lazy } from "react";

export const routeNames = {
  home: { name: "Home", path: "/" },
  index: { name: "Index", path: "/Home" },
  aboutUs: { name: "AboutUs", path: "/AboutUs" },
  howItWorks: { name: "HowItWorks", path: "/HowItWorks" },
  plans: { name: "Plans", path: "/Plans" },
  blog: { name: "Blog", path: "/Blog" },
  privacyPolicy: { name: "PrivacyPolicy", path: "/PrivacyPolicy" },
  terms: { name: "Terms", path: "/Terms" },
  contactUs: { name: "ContactUs", path: "/ContactUs" },
  login: { name: "Login", path: "/Login" },
  register: { name: "Register", path: "/Register" },
  forgotPwd: { name: "ForgotPwd", path: "/ForgotPassword" },
  properties: { name: "Properties", path: "/Properties" },
  propertyDetails: { name: "PropertyDetails", path: "/PropertyDetails" },
  dashboard: { name: "Dashboard", path: "/User/Dashboard" },
  ownertenants: { name: "OwnerTenants", path: "/User/Owner-Tenant" },
  owneragents: { name: "OwnerAgents", path: "/User/Owner-Agent" },
  ownerprofile: {
    name: "OwnerProfile",
    path: "/User/Owner-Profile",
  },
  ownerprofileedit: {
    name: "OwnerProfileEdit",
    path: "/User/Owner-Profile-Edit",
  },
  ownernotifications: {
    name: "OwnerNotifications",
    path: "/User/Owner-Notifications",
  },
  profiles: { name: "Profiles", path: "/User/Profiles" },
  upgradeplan: { name: "UpgradePlan", path: "/User/UpgradePlan" },
  userproperties: { name: "UserProperties", path: "/User/Properties/View" },
  addproperty: {
    name: "AddProperty",
    path: "/User/Properties/Add",
  },
  editproperty: {
    name: "EditProperty",
    path: "/User/Properties/Edit",
  },
  tenantowners: { name: "TenantOwners", path: "/User/Tenant-Owner" },
  tenantagents: { name: "TenantAgents", path: "/User/Tenant-Agent" },
  tenantconnectedproperties: {
    name: "TenantConnectedProperties",
    path: "/User/Tenant-ConnectedProperties",
  },
  tenantprofile: {
    name: "TenantProfile",
    path: "/User/Tenant-Profile",
  },

  tenantprofileedit: {
    name: "TenantProfileEdit",
    path: "/User/Tenant-Profile-Edit",
  },
  tenantnotifications: {
    name: "TenantNotifications",
    path: "/User/Tenant-Notifications",
  },
  tenantpayments: {
    name: "TenantPayments",
    path: "/User/Tenant-Payments",
  },
  tenantcheckout: {
    name: "TenantCheckout",
    path: "/User/Tenant-Checkout",
  },
  agentowners: { name: "TenantOwners", path: "/User/Agent-Owner" },
  agenttenants: { name: "TenantAgents", path: "/User/Agent-Tenant" },
  agentconnectedproperties: {
    name: "AgentConnectedProperties",
    path: "/User/Agent-ConnectedProperties",
  },
  agentprofile: {
    name: "AgentProfile",
    path: "/User/Agent-Profile",
  },
  agentprofileedit: {
    name: "AgentProfileEdit",
    path: "/User/Agent-Profile-Edit",
  },
  agentnotifications: {
    name: "AgentNotifications",
    path: "/User/Agent-Notifications",
  },
  comingup: { name: "ComingUp", path: "/User/ComingUp" },
  notFound: { name: "NotFound", path: "/*" },
};

const routes = [
  {
    path: routeNames.home.path,
    name: routeNames.home.name,
    element: lazy(() => import("../pages/Home")),
    isexact: true,
  },
  {
    path: routeNames.index.path,
    name: routeNames.index.name,
    element: lazy(() => import("../pages/Home")),
    isexact: true,
  },
  {
    path: routeNames.aboutUs.path,
    name: routeNames.aboutUs.name,
    element: lazy(() => import("../pages/AboutUs")),
    isexact: true,
  },
  {
    path: routeNames.howItWorks.path,
    name: routeNames.howItWorks.name,
    element: lazy(() => import("../pages/HowItWorks")),
    isexact: true,
  },
  {
    path: routeNames.privacyPolicy.path,
    name: routeNames.privacyPolicy.name,
    element: lazy(() => import("../pages/PrivacyPolicy")),
    isexact: true,
  },
  {
    path: routeNames.terms.path,
    name: routeNames.terms.name,
    element: lazy(() => import("../pages/Terms")),
    isexact: true,
  },
  {
    path: routeNames.plans.path,
    name: routeNames.plans.name,
    element: lazy(() => import("../pages/Plans")),
    isexact: true,
  },
  {
    path: routeNames.blog.path,
    name: routeNames.blog.name,
    element: lazy(() => import("../pages/Blog")),
    isexact: true,
  },
  {
    path: routeNames.contactUs.path,
    name: routeNames.contactUs.name,
    element: lazy(() => import("../pages/ContactUs")),
    isexact: true,
  },
  {
    path: routeNames.login.path,
    name: routeNames.login.name,
    element: lazy(() => import("../pages/Login")),
    isexact: true,
  },
  {
    path: routeNames.register.path,
    name: routeNames.register.name,
    element: lazy(() => import("../pages/Register")),
    isexact: true,
  },
  {
    path: routeNames.forgotPwd.path,
    name: routeNames.forgotPwd.name,
    element: lazy(() => import("../pages/ForgotPassword")),
    isexact: true,
  },
  {
    path: routeNames.properties.path,
    name: routeNames.properties.name,
    element: lazy(() => import("../pages/Properties")),
    isexact: true,
  },
  {
    path: routeNames.propertyDetails.path,
    name: routeNames.propertyDetails.name,
    element: lazy(() => import("../pages/PropertyDetails")),
    isexact: true,
  },
  {
    path: routeNames.dashboard.path,
    name: routeNames.dashboard.name,
    element: lazy(() => import("../pages/User/Dashboard")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.profiles.path,
    name: routeNames.profiles.name,
    element: lazy(() => import("../pages/User/Profiles")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.upgradeplan.path,
    name: routeNames.upgradeplan.name,
    element: lazy(() => import("../pages/User/UpgradePlan")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.userproperties.path,
    name: routeNames.userproperties.name,
    element: lazy(() => import("../pages/User/Properties/View")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.addproperty.path,
    name: routeNames.addproperty.name,
    element: lazy(() => import("../pages/User/Properties/Add")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.editproperty.path,
    name: routeNames.editproperty.name,
    element: lazy(() => import("../pages/User/Properties/Edit")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownertenants.path,
    name: routeNames.ownertenants.name,
    element: lazy(() => import("../pages/User/Owners/Tenants")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.owneragents.path,
    name: routeNames.owneragents.name,
    element: lazy(() => import("../pages/User/Owners/Agents")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerprofile.path,
    name: routeNames.ownerprofile.name,
    element: lazy(() => import("../pages/User/Owners/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownerprofileedit.path,
    name: routeNames.ownerprofileedit.name,
    element: lazy(() => import("../pages/User/Owners/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.ownernotifications.path,
    name: routeNames.ownernotifications.name,
    element: lazy(() => import("../pages/User/Owners/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantowners.path,
    name: routeNames.tenantowners.name,
    element: lazy(() => import("../pages/User/Tenants/Owners")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantagents.path,
    name: routeNames.tenantagents.name,
    element: lazy(() => import("../pages/User/Tenants/Agents")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantconnectedproperties.path,
    name: routeNames.tenantconnectedproperties.name,
    element: lazy(() => import("../pages/User/Tenants/ConnectedProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantprofile.path,
    name: routeNames.tenantprofile.name,
    element: lazy(() => import("../pages/User/Tenants/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantprofileedit.path,
    name: routeNames.tenantprofileedit.name,
    element: lazy(() => import("../pages/User/Tenants/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantnotifications.path,
    name: routeNames.tenantnotifications.name,
    element: lazy(() => import("../pages/User/Tenants/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantpayments.path,
    name: routeNames.tenantpayments.name,
    element: lazy(() => import("../pages/User/Tenants/Payments")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.tenantcheckout.path,
    name: routeNames.tenantcheckout.name,
    element: lazy(() => import("../pages/User/Tenants/Checkout")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentowners.path,
    name: routeNames.agentowners.name,
    element: lazy(() => import("../pages/User/Agents/Owners")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agenttenants.path,
    name: routeNames.agenttenants.name,
    element: lazy(() => import("../pages/User/Agents/Tenants")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentconnectedproperties.path,
    name: routeNames.agentconnectedproperties.name,
    element: lazy(() => import("../pages/User/Agents/ConnectedProperties")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentprofile.path,
    name: routeNames.agentprofile.name,
    element: lazy(() => import("../pages/User/Agents/Profile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentprofileedit.path,
    name: routeNames.agentprofileedit.name,
    element: lazy(() => import("../pages/User/Agents/EditProfile")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.agentnotifications.path,
    name: routeNames.agentnotifications.name,
    element: lazy(() => import("../pages/User/Agents/Notifications")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.comingup.path,
    name: routeNames.comingup.name,
    element: lazy(() => import("../pages/User/ComingUp")),
    isexact: true,
    isprotected: true,
  },
  {
    path: routeNames.notFound.path,
    name: routeNames.notFound.name,
    element: lazy(() => import("../pages/NotFound")),
  },
];

export default routes;
